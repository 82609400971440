import React, { useState } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  Button,
  Seo,
  Layout,
  HeroImage,
  Sections,
  Section,
  SectionTitle,
  SectionParagraph,
  InspirationCards,
} from "../components"
import { useForm } from "@formspree/react"
import { HiCheck, HiPaperAirplane, HiX } from "react-icons/hi"
import { AiOutlineLoading } from "react-icons/ai"
import config from "../../config.json"

export default function IndexPage({ data }) {
  const [readMoreCase1, setReadMoreCase1] = useState(false)
  const [readMoreCase2, setReadMoreCase2] = useState(false)

  const [state, handleSubmit] = useForm(config.formspree_id)
  const links = data.allMarkdownRemark.edges

  return (
    <Layout>
      <Seo title="Home" />
      <HeroImage
        title="Werkbare en gedragen digitaliserings&#8203;trajecten in de
                bouwsector"
        image={
          <StaticImage
            src="../assets/images/construct-hero.jpg"
            alt="Hero Image"
            className="h-full absolute top-0"
          />
        }
        logo={
          <StaticImage
            src="../assets/images/logos/logo-alert.png"
            alt="Alert!"
            width={300}
          />
        }
        logoBanner={
          <div className="flex flex-col items-center">
            <h3 className="text-2xl sm:text-3xl md:text-4xl text-white font-bold font-quicksand mb-5">
              In samenwerking met
            </h3>
            <div className="flex flex-col lg:flex-row items-center justify-between space-x-5 space-y-5 lg:space-y-0 min-h-24 bg-white p-5 rounded-xl">
              <div className="flex-grow flex flex-row items-center justify-between space-x-5">
                <StaticImage
                  src="../assets/images/logos/logo-agentschap-io.png"
                  alt="Agentschap innoveren en ondernemen"
                  height={50}
                />
                <StaticImage
                  src="../assets/images/logos/logo-ai-vlaanderen.png"
                  alt="AI vlaanderen"
                  height={50}
                />
                <StaticImage
                  src="../assets/images/logos/logo-esf.jpg"
                  alt="ESF"
                  height={50}
                />
              </div>
              <div className="flex-grow flex flex-row items-center justify-between space-x-5">
                <StaticImage
                  src="../assets/images/logos/logo-vlaanderen.jpg"
                  alt="Vlaanderen is werk"
                  height={50}
                />
                <StaticImage
                  src="../assets/images/logos/logo-crunch.png"
                  alt="Crunch Analytics"
                  height={50}
                />
                <StaticImage
                  src="../assets/images/logos/logo-confederatiebouw.png"
                  alt="Confederatie Bouw"
                  height={50}
                />
              </div>
            </div>
          </div>
        }
      />
      <Sections>
        <Section className="md:flex-row">
          <div className="flex-1 space-y-2">
            <SectionTitle>Digitalisering</SectionTitle>
            <SectionParagraph>
              Digitalisering is niet meer te stoppen, ook niet in de bouwsector.
              Meer en meer bedrijven maken de digitale transformatie. En bij
              deze transformatie komt heel wat kijken. Om bouwbedrijven te
              ondersteunen bij deze digitale transformatie, slaan Confederatie
              Bouw, Crunch en Alert! de handen in elkaar.
            </SectionParagraph>
            <SectionParagraph>
              Via deze website vind je handige tools en ondersteuning om met
              digitalisering in jouw bouwbedrijf aan de slag te gaan.
            </SectionParagraph>
            <SectionParagraph>
              We focussen ons op de bouwstenen die van jouw
              digitaliseringstraject een succesverhaal kunnen maken.
            </SectionParagraph>
          </div>
          <div className="flex flex-col space-y-5 my-3 sm:flex-row items-center justify-between px-5 pt-2 space-x-5 md:pt-0 md:space-x-0 md:flex-col md:w-1/3">
            <StaticImage
              src="../assets/images/logos/logo-alert-gray.png"
              alt="Alert!"
              width={200}
            />
            <StaticImage
              src="../assets/images/logos/logo-confederatiebouw.png"
              alt="Confederatie Bouw"
              width={200}
            />
            <StaticImage
              src="../assets/images/logos/logo-crunch.png"
              alt="Crunch Analytics"
              width={200}
            />
          </div>
        </Section>
        <Section>
          <SectionTitle>Doe de digitale scan</SectionTitle>
          <SectionParagraph>
            Is jouw bedrijf klaar om te digitaliseren? Waar zitten de sterke
            punten en de groeipunten van jouw bedrijf?
          </SectionParagraph>
          <SectionParagraph>
            Ontdek ze door de digitale scan in te vullen. Het invullen van de
            scan duurt ongeveer een half uur en geeft je bedrijf achteraf een
            analyserapport op maat. Hier ontdek je waar je nog kan op inzetten
            om van jouw digitalisering een succesverhaal te maken.
          </SectionParagraph>
          <div className="pt-4">
            <Button isLink={true} to={config.scan_url}>
              Doe de scan!
            </Button>
          </div>
        </Section>
        <Section id="inspiration">
          <SectionTitle>Laat je inspireren</SectionTitle>
          <SectionParagraph>
            Doorloop volgende stappen om je verder te inspireren:
            <ul className="list-decimal list-inside">
              <li>
                Zoek cursussen: geef de naam in van een van de cursussen (bv.
                Kennisdeling en kennisborging)
              </li>
              <li>
                Klik op de naam van de cursus bv. 'Kennisdeling en
                kennisborging'
              </li>
              <li>
                Nieuwe account maken (dit is éénmalig en eenvoudig toepasbaar)
              </li>
              <li>Voltooien registratie: open e-mail</li>
              <li>Registratie is bevestigd - Ga door</li>
              <li>Aanmeldingssleutel: Digitalisering</li>
            </ul>
          </SectionParagraph>
          <InspirationCards links={links} />
        </Section>
        <Section>
          <SectionTitle>Inspirerende cases</SectionTitle>
          <SectionParagraph>
            Gelukkig zijn er al heel wat bedrijven die hun digitaal
            transformatie tot een succes hebben uitgerold binnen hun bedrijf. In
            dit artikel laten we een aantal bedrijven aan het woord. Zij geven
            hun aanbevelingen voor een geslaagd digitaal transformatietraject.
            Laat u inspireren om zo goed mogelijk te transformeren.
          </SectionParagraph>
          <SectionTitle className="text-2xl pt-5">
            Case 1: EEG Group
          </SectionTitle>
          <SectionParagraph>
            EEG Group is een familiale groep gespecialiseerd in
            gebouwentechnieken en met 13 vestigingen aanwezig in heel België.
            EEG Group brengt duurzaam comfort in gebouwen door de integratie van
            elektrische totaaloplossingen, HVAC en sanitaire installaties. De
            diensten reiken van ontwerp, installatie, integratie en onderhoud
            tot exploitatie. En dit zowel voor eenvoudige als buitengewoon
            complexe projecten.
          </SectionParagraph>
          {readMoreCase1 ? (
            <>
              <SectionParagraph>
                In 2016 sloot Elektro Kamiel Smet zich aan bij de EEG Group. Als
                onderdeel van de EEG Group speelt Elektro Kamiel Smet een
                toonaangevende rol op vlak van elektrische installaties,
                vernieuwende technologieën, energiebeheer en operationeel beheer
                op lange termijn.
              </SectionParagraph>
              <SectionParagraph>
                Het bedrijf zit volop in een transformatiefase, en geeft alvast
                volgende succesfactoren mee:
                <div className="space-y-1 py-1">
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Betrek alle functies binnen het bedrijf: gebruikers moeten
                    de kans krijgen om mee na te denken over de digitale
                    transformatie.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Werk met ambassadeurs die op hun beurt collega's motiveren
                    en inspireren.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Vergeet ook de leden van het managementteam niet: geef hen
                    een actieve rol in het digitale project.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Laat mensen dromen: start vanuit wat de mensen willen en hoe
                    ze willen werken.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Zorg voor een trekker van het project. Bij voorkeur iemand
                    die het bedrijf én de mensen kent.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Denk goed na waarom je digitaliseert: laat kostenbesparing
                    niet de enige reden zijn. Denk na wat de positieve impact
                    zal zijn voor klant, bedrijf én medewerker.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Verkies een traject op maat, liever dan digitale
                    standaardoplossingen.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Werk met “reversed mentorship”. Laat de medewerkers die
                    digitale competenties reeds bezitten anderen begeleiden en
                    coachen.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Kom te weten welke basiskennis vereist is bij de medewerkers
                    om goed met de digitalisering te starten.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Organiseer een voortraject voor je medewerkers. Dit kan
                    eventueel één op één. Zo kan je aan iedereen het
                    noodzakelijke basisniveau meegeven.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Besef dat digitalisering the war for talent kan
                    vergemakkelijken.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Vervang geen mensen door op digitalisering in te zetten.
                  </div>
                </div>
              </SectionParagraph>
              <SectionParagraph>
                <button
                  onClick={() => setReadMoreCase1(false)}
                  className="text-info font-bold"
                >
                  Lees minder...
                </button>
              </SectionParagraph>
            </>
          ) : (
            <SectionParagraph>
              <button
                onClick={() => setReadMoreCase1(true)}
                className="text-info font-bold"
              >
                Lees meer...
              </button>
            </SectionParagraph>
          )}
          <SectionTitle className="text-2xl pt-5">
            Case 2: Van Cauter
          </SectionTitle>
          <SectionParagraph>
            Van Cauter is een familiebedrijf dat in 1990 gestart is. Het bedrijf
            wil een antwoord bieden op technische uitdagingen bij klanten. Het
            bedrijf startte met een 15-tal personen, ondertussen werken een
            100-tal medewerkers binnen het bedrijf. Dagelijks zetten zij zich in
            om mooie projecten rond algemene elektriciteit, HVAC, beveiliging en
            onderhoud te realiseren.
          </SectionParagraph>
          {readMoreCase2 ? (
            <>
              <SectionParagraph>
                De klantenfocus ligt sterk op de openbare sector. Ongeveer 90%
                van de klanten behoort tot de nonprofitsector. Klanten zoals de
                Regie der Gebouwen, Hogeschool Gent, NMBS,… konden al rekenen op
                de expertise van Van Cauter.
              </SectionParagraph>
              <SectionParagraph>
                Het bedrijf startte een tiental jaar terug met het digitaliseren
                van haar processen. Uit de afgelopen tien jaar trekt het bedrijf
                volgende lessen:
                <div className="space-y-1 py-1">
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Ga voor een digitale evolutie, eerder dan een digitale
                    revolutie. Zorg dat er een digitale mindset aanwezig is.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Een centrale persoon die de digitalisering coördineert, is
                    een grote meerwaarde.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Betrek medewerkers bij beslissingen en het evalueren van de
                    digitalisering.
                  </div>
                  <div className="flex flex-row items-start">
                    <HiCheck className="text-2xl mr-4 min-w-max" />
                    Investeer in opleidingen voor de gebruikers van de digitale
                    tools. Zowel voor nieuwe medewerkers als ervaren gebruikers
                    (update).
                  </div>
                </div>
              </SectionParagraph>
              <SectionParagraph>
                <button
                  onClick={() => setReadMoreCase2(false)}
                  className="text-info font-bold"
                >
                  Lees minder...
                </button>
              </SectionParagraph>
            </>
          ) : (
            <SectionParagraph>
              <button
                onClick={() => setReadMoreCase2(true)}
                className="text-info font-bold"
              >
                Lees meer...
              </button>
            </SectionParagraph>
          )}
        </Section>
        <Section>
          <h1 className="text-4xl font-bold text-neutral">Contact</h1>
          <div className="w-20 border-t-2 mt-4 pb-6 border-neutral" />
          <form
            name="contact"
            className="flex flex-col w-full md:w-3/4"
            onSubmit={handleSubmit}
          >
            <label className="font-quicksand font-bold" htmlFor="name">
              Naam
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="border-2 border-info p-2 rounded-lg mb-2"
            />
            <label className="font-quicksand font-bold" htmlFor="email">
              Email adres
            </label>
            <input
              type="text"
              name="email"
              id="email"
              className="border-2 border-error p-2 rounded-lg mb-2"
            />
            <label className="font-quicksand font-bold" htmlFor="subject">
              Onderwerp
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              className="border-2 border-success p-2 rounded-lg mb-2"
            />
            <label className="font-quicksand font-bold" htmlFor="message">
              Bericht
            </label>
            <textarea
              type="text"
              name="message"
              id="message"
              className="border-2 border-warning p-2 rounded-lg mb-4 h-36"
            />
            <button
              type="submit"
              className="bg-info py-2 rounded-lg shadow-sm hover:shadow-md font-bold font-quicksand text-lg text-white flex flex-row items-center justify-center"
              disabled={state.submitting}
            >
              {state.submitting ? (
                <AiOutlineLoading className="mr-2 animate-spin" />
              ) : state.errors && state.errors.length ? (
                <HiX className="mr-2" />
              ) : state.succeeded ? (
                <HiCheck className="mr-2" />
              ) : (
                <HiPaperAirplane className="mr-2" />
              )}
              Verzenden
            </button>
          </form>
        </Section>
      </Sections>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            icon
            color
            url
            order
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
